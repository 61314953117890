<template>
    <div class="html-default">
        <table border="1" class="mytable mytable-head">
            <thead>
                <tr>
                    <td class="px-3" width="20%" style="text-align: center;"><label>No Pembayaran Invoice</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Tipe Pembayaran Invoice</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Status</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Tanggal Pembayaran Invoice</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Metode Pembayaran</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Bank</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Cabang</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Nomor Akun</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Nama Akun</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Pembulatan</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Biaya Admin</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Biaya Lainnya</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Nomor Supplier Invoice</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Invoice</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Total Pembayaran</label></td>
                    <td class="px-3" width="20%" style="text-align: center;"><label>Keterangan</label></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td class="px-2"><label>{{ item['inv_payment_number'] }}</label></td>
                    <td class="px-2"><label>{{ item['inv_payment_type'] }}</label></td>
                    <td class="px-2"><label>{{ item['status'] }}</label></td>
                    <td class="px-2" >
                        <label>
                            {{item['inv_payment_date'] == "" || item['inv_payment_date'] == null ? '-' : 
                            new Date(item['inv_payment_date']).toLocaleDateString('es-CL') }}
                        </label>
                    </td>
                    <td class="px-2"><label>{{ item['payment_method'] }}</label></td>
                    <td class="px-2"><label>{{ item['bank'] }}</label></td>
                    <td class="px-2"><label>{{ item['branch'] }}</label></td>
                    <td class="px-2"><label>{{ item['account_number'] }}</label></td>
                    <td class="px-2"><label>{{ item['account_name'] }}</label></td>
                    <td class="px-2"><label>{{ item['round_up'] }}</label></td>
                    <td class="px-2"><label>{{ item['delivery_fee'] }}</label></td>
                    <td class="px-2"><label>{{ item['admin_fee'] }}</label></td>
                    <td class="px-2"><label>{{ item['other_fee'] }}</label></td>
                    <td class="px-2"><label>{{ item['invoice_number'] }}</label></td>
                    <td class="px-2"><label>{{ item['total_payment'] }}</label></td>
                    <td class="px-2"><label>{{ item['notes'] }}</label></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import { response } from '../../infrastructure/constant/response';
import PurchasingInvoicePaymentReportService from '../../Areas/Reporting/PurchasingInvoicePaymentReport/Script/PurchasingInvoicePaymentReportService';

export default {
    name: 'PurchasingInvoicePaymentReportHtml',
    data(){
        return{
            StartDate: this.$route.params.startdate,
            EndDate: this.$route.params.enddate,
            items: [],
        }
    },
    async mounted() {
        const variables = {
            startDate: this.StartDate,
            endDate: this.EndDate
        }
        var data = await PurchasingInvoicePaymentReportService.getPurchasingInvoicePaymentReport(variables);
        this.items = data.detail;
    },
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>